.loader120 {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0e447b; /* Shippers Edge Dark Blue */
    border-bottom: 16px solid #32abd4; /* Shippers Edge Light Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .loader60 {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #0e447b; /* Shippers Edge Dark Blue */
    border-bottom: 8px solid #32abd4; /* Shippers Edge Light Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  /* f3f3f3 */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }