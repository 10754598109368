.App {
    background-color: #ffffff;
    min-height: 100vh;
}

.card-chart {
    min-width: 550px !important;
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    /* gray shade */
    background-color: #f8f9fa;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .TitleText {
    font-family: "Titillium Web", sans-serif;
    font-size: calc(20px + 2vmin);
    line-height: 1.125;
  }

.niceBackground {
    background-color: #f6f6ef;
}

.bi-table {
    background-color: #ffffff;
}

.xx-small-font {
    font-size: xx-small;
}

.x-small-font {
    font-size: x-small;
}

.width300px {
    width: 350px;
}

.width350px {
    width: 350px;
}

.width400px {
    width: 400px;
}

.width450px {
    width: 450px;
}

.width500px {
    width: 500px;
}


.width800px {
    width: 800;
}

.width1000px {
    width: 1000px;
}

.height500{
    height: 500px;
}


.likeALink {
    cursor:pointer;
    
    
}
.mt-neg-2 {
    margin-top: -0.5rem !important;
  }


/**===== circle6 =====*/
#circle6 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 10px #008744 solid;
    border-right: 10px #0057e7 solid;
    border-bottom: 10px #d62d20 solid;
    border-left: 10px #ffa700 solid;
    border-radius: 50%;
    -webkit-animation: spin6 1s infinite linear;
            animation: spin6 1s infinite linear;
  }

  @-webkit-keyframes spin6 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes spin6 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  /** END of circle6 */
